import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRouteSnapshot,Resolve,RouterStateSnapshot} from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: "root",
})

export class ListService implements Resolve<any> {
  private _url: string;
  practitiensSubject : Subject<any[]> = new Subject<any[]>();
  annuairesSubject : Subject<any[]> = new Subject<any[]>();
  practiciens = [];
  annuaires = [];
  rdvSubject = new BehaviorSubject<any>(null);
  rdv;

  /**
   * Constructor
   *
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this._url = environment.url_api;
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        // this.getCalls(),
        // this.getUserData()
      ]).then(([]) => {
        resolve([]);
      }, reject);
    });
  }
  /**
   * Add Call
   *
   * @param data
   * @returns {Promise<any>}
   */
  getTimesSlote(data: any): Promise<any> {
    /**
     * Params
     */
    let httpParams = new HttpParams();
    // httpParams = httpParams.append("Param_test", data.param_test);
    httpParams = httpParams.append("Param_idPraticien", "1");
    httpParams = httpParams.append("Param_NbrDays", data.display_days);
    httpParams = httpParams.append("Param_DateInit", data.date_init);
    httpParams = httpParams.append("Param_IDTypeRdv", "22");
    httpParams = httpParams.append("Param_idDefault", "");

    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/public/getcreneaux", httpParams, {
          // headers: headers,
          // params: httpParams,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (response: any) => {
            //  console.log(response);
            resolve(response);
          },
          (err) => {
            console.log("Error: " + err.error);
            console.log("Name: " + err.name);
            console.log("Message: " + err.message);
            console.log("Status: " + err.status);
          },
          reject
        );
    });
  }


  /////////////////////////



  /**
   * Retreive Person Data
   * * @param keyword
   * *@param data: Array
   * * @returns {Promise<any>}
   */
  searchSp(Data: any): Promise<any> {
    /**
     * Params
     */
    var httpParams = new HttpParams();
    httpParams = httpParams.append("Param_keyword", Data.keyword);
    httpParams = httpParams.append("Param_ville", Data.ville);
    httpParams = httpParams.append("Param_pays", Data.pays);
    //  httpParams = httpParams.append('Param_Page', Data.page);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/practicesBD/search_sp", httpParams, {
          //headers: headers,
          //params: httpParams,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (response: any) => {
           // console.log(response);
            resolve(response);
          },
          (err) => {
            console.log("Error: " + err.error);
            console.log("Name: " + err.name);
            console.log("Message: " + err.message);
            console.log("Status: " + err.status);
          },
          reject
        );
    });
  }
  /**
   * get Call Histories
   *
   * @param Data: Array
   * @returns {Promise<any>}
   */
  searchResults(Data: any): Promise<any> {
    /**
     * Data
     */
    //console.log('Start getTypesCall Data Params ', Data);
    /**
     * Header
     */
    //let headers = new HttpHeaders();
    //headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    /**
     * Params
     */
    let httpParams = new HttpParams();
    httpParams = httpParams.append("Param_URL_Spec", Data.spec);
    httpParams = httpParams.append("Param_Ville", Data.ville);
    httpParams = httpParams.append("Param_Pays", Data.pays);
    //  httpParams = httpParams.append('Param_Page', Data.page);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/public/listePraticiens", httpParams, {
          //headers: headers,
          params: httpParams,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.practiciens=response.body.data.praticiens;
            this.emitPracticiens();
            this.annuaires =response.body.data.annuaires;
            this.emitAnnuaires();
            resolve(response);
          },
          (err) => {
            console.log("Error: " + err.error);
            console.log("Name: " + err.name);
            console.log("Message: " + err.message);
            console.log("Status: " + err.status);
          },
          reject
        );
    });
  }

  emitPracticiens(){
    this.practitiensSubject.next(this.practiciens);
  }

  emitAnnuaires(){
    this.annuairesSubject.next(this.annuaires);
  }

  updateRdv(practicien,time,timeSlot){
    this.rdv = {
        practicien : practicien,
        time : time,
        timeSlot : timeSlot,
    }
    console.log(this.rdv);
    this.emitRdv();
  }

  emitRdv(){
      this.rdvSubject.next(this.rdv);
  }
}
