import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BookingComponent } from '../booking.component';
import {environment} from "../../../../environments/environment";
import {SignInComponent} from "../../security/sign-in/google/sign-in.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {SignInPageComponent} from "../../security/sign-in/sign-in-page.component";
import {InscriptionComponent} from "../inscription/inscription.component";
import {SharedModule} from "../../../shared/shared.module";
@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    SignInComponent,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    SignInPageComponent,
    InscriptionComponent,
    SharedModule
  ],
  providers:[]
})
export class AuthenticationComponent implements OnInit  {

  @ViewChild('horizontalStepper') private myStepper: MatStepper;
  error = false;
  public hide = true;
  public hidden : boolean = true;
  @Input() connected : boolean;
  @Output() newItemEvent = new EventEmitter<FormGroup>();

  constructor(private bookingComponent : BookingComponent) { }
  ngOnInit(): void {}
  showpage() {
    this.hidden = !this.hidden;
  }
  nextStep() {
    this.bookingComponent.verifConnected();
  }
  protected readonly environment = environment;
}