<div class="step-content">
    <mat-card class="bg-primary mb-16">
        <p class="py-2 px-1 ">
            Choissisez...
        </p>
    </mat-card>
    <mat-card class="p-0">
        <mat-table #table [dataSource]="dataSource" fusePerfectScrollbar>


            <!-- Checkbox Column -->
            <ng-container matColumnDef="checkbox">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <mat-checkbox [checked]="selected === contact.reference" (change)="onChange(contact.reference)"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <img height="27px" width="27px" class="avatar" *ngIf="contact.reference"
                        src="/assets/images/avatars/avatar-3.png" />
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <p class="text-truncate font-weight-600">{{contact.first_name}} {{contact.last_name}}</p>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef>Catégorie</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <p class="text-truncate font-weight-600 ml-3" *ngIf="contact.is_principal == true">vous</p>
                    <p class="text-truncate font-weight-600" *ngIf="contact.is_principal == false">
                        {{ contact.category}}</p>
                </mat-cell>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <div class="text-center">
                        <!--  (click)="$event.stopPropagation();toggleStar(contact.referance)" -->
                        <button mat-icon-button aria-label="Toggle star">
                            <!-- <mat-icon class="amber-fg" *ngIf="user.starred.includes(contact.id)">star</mat-icon> -->
                            <mat-icon class="secondary-text">star_border</mat-icon>
                        </button>
                        <!--  (click)="$event.stopPropagation();" -->
                        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>

                        <mat-menu #moreMenu="matMenu">
                            <!--  (click)="deleteContact(contact)" -->
                            <button mat-menu-item aria-label="remove">
                                <mat-icon>delete</mat-icon>
                                <span>Remove</span>
                            </button>
                        </mat-menu>
                    </div>

                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let contact; columns: displayedColumns;" class="contact"
                [ngClass]="{'accent-50':true}">
            </mat-row>
        </mat-table>
    </mat-card>
    <mat-card class="primary-50-bg mt-16">
        <textarea placeholder="Commentaire" class="p-4"></textarea>
    </mat-card>
    <div>
        <div class="flex justify-between items-center mt-3 p-2">
            <button matStepperPrevious class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                <mat-icon>navigate_before</mat-icon>
                <span class="uppercase">Retour</span>
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button" (click)="confirmerRdv()">
                <span class="uppercase mr-1">Confirmer le rendez-vous</span>
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</div>