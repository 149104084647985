import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class  ProfileService
{

    _url_java: any;
    directory: any;
    timeline: any;
    about: any;
    photosVideos: any;
    timelineOnChanged: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _eaHttpAbstract
     */
    constructor(
        private _httpClient: HttpClient,
        private _eaHttpAbstract: EaHttpAbstract,
    ) {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject({});
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
        this._url_java = environment.url_java;
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    // {
    //     return new Promise((resolve, reject) => {
    //         Promise.all([
    //             this.getAnnuaireSante([]),
    //             this.getTimeline(),
    //             this.getAbout(),
    //             this.getPhotosVideos()
    //         ]).then(
    //             ([directory, Timeline, About, PhotosVideos]) => {
    //                 this.directory = directory.body;
    //                 resolve(true);
    //             },
    //             reject
    //         );
    //     });
    // }

    /**
     * Get update infos profile
     */
    // updateInfosProfile(data){
    //     var httpParams = new HttpParams();
    //     httpParams = httpParams.append('Param_Oper', 'profile');
    //     httpParams = httpParams.append('Param_Nom', data.nom);
    //     httpParams = httpParams.append('Param_Prenom', data.prenom);
    //     httpParams = httpParams.append('Param_Civilite', data.civilite);
    //     httpParams = httpParams.append('Param_DateNaissance', data.datenaiss);
    //
    //     return new Promise((resolve,reject)=>{
    //         this._httpClient.post(this._url + '/patients/updateInfosAccount',httpParams,{
    //             responseType: 'json',
    //             withCredentials: true,
    //             observe: 'response'
    //         }).subscribe((response : any)=>{
    //             if (response.code === 1) {
    //                 resolve(response.data);
    //             } else{
    //                 resolve([]);
    //             }
    //         });
    //     })
    // }
        /**
     * Update Profile Picture
     */
    // uploadImage(data: any){
    //     var httpParams = new HttpParams();
    //     httpParams = httpParams.append('image', data);

    //     return new Promise((resolve,reject)=>{
    //         this._httpClient.post(this._url + '/account/updateUserPicture',httpParams,{
    //             responseType: 'json',
    //             withCredentials: true,
    //             observe: 'response'
    //         }).subscribe((response : any)=>{
    //             console.log(response.body);
    //         });
    //     })
    // }
    // uploadProfileData(formData: FormData) : Observable<any>{
    //     const data = {};
    //     return this._eaHttpAbstract.post("/patient_area/update_profile", formData)
    //         .pipe(
    //             map(response=>{
    //                 return response;
    //             })
    //         );
    //   }
    uploadProfileData(formData: FormData) {
        return this._httpClient
            .post(`${this._url_java}/patient_area/update_profile`, formData, {
                withCredentials: true,
                responseType: "json",
                observe: "response",
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }

    /**
     * Get authentication
     */
    // authentication() : Promise<any[]>{
        // var httpParams = new HttpParams();
        // httpParams = httpParams.append('Param_Login', 'demo1');
        // httpParams = httpParams.append('Param_Password', '12369');
        //
        // return new Promise((resolve,reject)=>{
        //     this._httpClient.post(this._url + '/security/authentification',httpParams,{
        //         responseType: 'json',
        //         withCredentials: true,
        //         observe: 'response'
        //     }).subscribe((response : any)=>{
        //         console.log(response.body);
        //     });
        // })
    // }
    /**
    * Get infos Profile
    *
    * @returns Promise<any>
    **/
    // getInfosAccount(): Promise<any> {
    //     return new Promise((resolve) => {
    //         let httpParams = new HttpParams();
    //         this._httpClient
    //             .post(this._url + "/patients/findPatientByUserId",httpParams, {
    //                 responseType: "json",
    //                 withCredentials: true,
    //                 observe: "response",
    //             })
    //             .subscribe(
    //                 (response: any) => {
    //                     if (response.body.code === 1) {
    //                         resolve(response.body.data);
    //                     } else{
    //                         resolve([]);
    //                  }
    //                 }
    //             );
    //     });
    // }

    /**
     * Get timeline
     */
    getTimeline(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/profile-timeline')
                .subscribe((timeline: any) => {
                    this.timeline = timeline;
                    this.timelineOnChanged.next(this.timeline);
                    resolve(this.timeline);
                }, reject);
        });
    }

    /**
     * Get about
     */
    getAbout(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/profile-about')
                .subscribe((about: any) => {
                    this.about = about;
                    this.aboutOnChanged.next(this.about);
                    resolve(this.about);
                }, reject);
        });
    }

    /**
     * Get photos & videos
     */
    getPhotosVideos(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/profile-photos-videos')
                .subscribe((photosVideos: any) => {
                    this.photosVideos = photosVideos;
                    this.photosVideosOnChanged.next(this.photosVideos);
                    resolve(this.photosVideos);
                }, reject);
        });
    }








    /**
     * get AnnuaireSante
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    // getAnnuaireSante(Data: any): Promise<any> {
    //     /**
    //      * Data
    //      */
    //     // console.log('Start getTypesCall Data Params ', Data);
    //     /**
    //      * Header
    //      */
    //     // let headers = new HttpHeaders();
    //     // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    //
    //     /**
    //      * Params
    //      */
    //     let httpParams = new HttpParams();
    //     httpParams = httpParams.append('Param_url', 'dr_tictacsante');
    //
    //
    //     return new Promise((resolve, reject) => {
    //
    //         this._httpClient.post( this._url + '/public/getAnnuaireSante', httpParams,
    //             {
    //                 // headers: headers,
    //                 // params: httpParams,
    //                 responseType: 'json',
    //                 withCredentials: true,
    //                 observe: 'response'
    //             }).subscribe((response: any) => {
    //                 //console.log(response);
    //                 resolve(response);
    //             },
    //             err => {
    //                 console.log('Error: ' + err.error);
    //                 console.log('Name: ' + err.name);
    //                 console.log('Message: ' + err.message);
    //                 console.log('Status: ' + err.status);
    //             }, reject);
    //     });
    // }
}
