import { Component, OnInit } from '@angular/core';
import {DEFAULT_PHOTO, defaultPhoto} from "../../../constants/constants";
import {ImageUtils} from "../../../../@ea/helpers/image-utils";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
@Component({
  selector: 'app-sammary',
  templateUrl: './sammary.component.html',
  styleUrls: ['./sammary.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    CommonModule,
    SharedModule
  ],
})
export class SammaryComponent implements OnInit {
  appointement: any;
  practice: any;
  timeSlot: any;
  time: any;
  motif: any;
  dataPractice: any;
  constructor() {}

  ngOnInit(): void {
    const appointement= localStorage.getItem('appointement');
    this.appointement = JSON.parse(appointement);
    this.practice = this.appointement?.practice;
    this.timeSlot = this.appointement?.timeSlot;
    this.time = this.appointement?.time;
    this.motif = this.appointement?.motif;
    this.dataPractice = {
      title:   this.practice?.title,
      specialty: this.practice?.speciality,
      city: this.practice?.city,
      photo: this.practice?.picture
    }
  }

  defaultAvatarTTSUrl = DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
  imageUtils = new ImageUtils();
}
