<div class="step-content">
    <mat-card class="doctor-widget doctor-dashboard" >
        <div class="doc-info-left">
            <div class="doctor-img">
                <a class="doctor-img-profile" href="#">
                    <img
                            [src]="'https://cdn.tictacsante.com/assets/images/doc/' + practice?.picture"
                            class="img-fluid img-circle" alt="User Image"
                            (error)="handleImageError($event)"
                    ><!--[src]="imageUtils.getPhoto(practice.photo)"-->
                </a>
            </div>
            <div class="doc-info-cont">
                <h4 class="doc-name m-0">{{dataPractice.title}}</h4>
                <div class="toggle-icon-doctor">
                    <span class="doc-department">
                        <span class="speciality-img">
                            <img src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg" class="img-fluid"
                                 alt="Speciality">
                        </span>
                        <span class="speciality-name">
                            {{dataPractice?.specialty}}
                        </span>
                    </span>
                </div>
                <div class="rating">
                    <mat-icon>star_border</mat-icon>
                    <mat-icon>star_border</mat-icon>
                    <mat-icon>star_border</mat-icon>
                    <mat-icon>star_border</mat-icon>
                    <mat-icon>star_border</mat-icon>
                    <span class="d-inline-block average-rating">(0)</span>
                </div>
                <div class="doc-info custom-info">
                    <div class="doc-location">
                        <p>
                            <mat-icon>location_on</mat-icon> {{dataPractice?.city}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="booking-summary">
        <div class="title-col">
            <h5 class="mt-0">Détails du rendez-vous</h5>
        </div>
        <div class="summary-col">
            <mat-card class="summary-col-inner" *ngIf="timeSlot">
                <div>Consultation <span>Au cabinet</span></div>
                <div>Date <span>{{timeSlot.date | date}}</span></div>
                <div>Heure<span>{{time.hour_text}}</span></div>
                <div>Motif<span>{{motif?.type_name | titlecase }}</span></div>
            </mat-card>
        </div>

    </div>
    <div>
        <div class="flex justify-between items-center mt-3 p-2">
            <button matStepperPrevious class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                <mat-icon>navigate_before</mat-icon>
                <span class="uppercase">Retour</span>
            </button>
            <button matStepperNext class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                <span class="uppercase mr-1">Suivant</span>
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</div>

