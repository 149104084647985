import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";

@Injectable()
export class PlanningService {
    times_slote: any;
    onSelectedTime: BehaviorSubject<any>;
    // Private
    private _url: string;
    rdv: { practicien: any; time: any; timeSlot: any; };
    rdvSubject : BehaviorSubject<any | null> = new BehaviorSubject(null);

    /**
     * Constructor
     *
     * @param _eaHttpAbstract
     */
    constructor(private _eaHttpAbstract: EaHttpAbstract) {
        this.onSelectedTime = new BehaviorSubject([]);
        this.rdvSubject = new BehaviorSubject([]);
        this._url = environment.url_api;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Add Call
     *
     * @param data
     * @returns {Promise<any>}
     */
    getTimesSlot(data: any): Observable<any> {
        /**
         * Params
         */
        const _data: any = {};
        _data.reference = data.reference;
        _data.nbr_of_days = data.display_days;
        _data.date_init = data.date_init;
        _data.reference_type = data.refType;
        return this._eaHttpAbstract.post("/public/planning", _data).pipe(
            map(response=>{
                return response;
            })
        );
    }

    /**
     * Add Call
     *
     * @param data
     */
    startProcessRdv(data: any): Observable<any> {
        /**
         * Params
         */
        let _data = {
            practice_reference: data.reference,
            start_hour: data.hour,
            type: data.type_ref,
            date: data.date_init
        }
        return this._eaHttpAbstract.post("/public/booking/start_process", _data).pipe(
            map(response=>{
                return response;
            })
        );
    }
    getMotifs(data: any): Observable<any> {
        /**
         * Params
         */
        const _data = {
            practice_reference: data.reference
        }
        return this._eaHttpAbstract.post("/public/planning/reasons", _data).pipe(
            map(response=>{
                return response;
            })
        );
    }

    selectedTime(data: any) {
        this.onSelectedTime.next(data);
    }
}
